import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const SubTitle = styled.h3`
  font-size: 20px;
  font-weight: 500;
`;

//icon : 아이콘보다 큰사이즈로 설정 : 터치영역
export const IconButton = styled.button`
  min-width: 24px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PaginationBox = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.top {
    margin-top: 0;
    min-width: 460px;
  }

  & .MuiPaginationItem-page.Mui-disabled {
    background: none;
  }
  & .MuiPaginationItem-root {
    opacity: 1;
    color: #888;
    background: none;
  }
  .MuiPaginationItem-icon {
    color: #222;
  }
  & .MuiPaginationItem-page.Mui-selected {
    background: none;
    font-size: 14px;
    font-weight: 500;
    opacity: 1;
    color: #0086e5;
    &:hover {
      background: none;
    }
  }

  & .MuiPaginationItem-page:hover {
    background: none;
  }
`;

export const LabelText = styled.p`
  font-size: 14px;
  font-weight: 500;
  min-width: 100px;
  width: 100px;
  height: 40px;
  display: flex;
  align-items: center;
  margin-right: 8px;

  &.underline {
    text-decoration: underline;
  }

  &.w_140 {
    min-width: 140px;
    width: 140px;
  }
  & > span {
    color: #0086e5;
    display: block;
    font-size: 12px;
    font-weight: 400;
  }
`;

export const InputSection = styled.div`
  margin-top: 24px;

  &.small {
    margin-top: 16px;
  }
`;

export const FilterBox = styled.div`
  margin: 16px 0 32px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #fff;
  padding: 24px;
`;

export const InputFlexBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  & > div {
    margin-right: 4px;
  }

  &.flex_start {
    align-items: flex-start;
  }

  &.margin_80 {
    margin-bottom: 0;
    & > div {
      margin-right: 80px;
      margin-bottom: 0;
    }
  }
  &.marketing {
    margin: 16px 0 0;
  }
  &.margin_bottom16 {
    margin-bottom: 16px;
  }
  &.flex {
    display: flex;
    align-items: baseline;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #eee;
  margin: 12px 0;

  &.large {
    margin: 24px 0;
  }

  &.black {
    background: #222;
  }
`;

export const ContentsArea = styled.div`
  padding: 32px 24px 0;
`;

export const DatePicker = styled.div`
  display: flex;

  &.normal_datepicker span {
    height: 40px;
  }

  & > span {
    border-top: 1px solid #bbb;
    border-bottom: 1px solid #bbb;
    width: 16px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const RegisterButtonArea = styled.div`
  margin-top: 60px;
  display: flex;
  justify-content: center;

  & > button {
    margin-right: 8px;
  }

  & > button:last-of-type {
    margin-right: 0;
  }
`;
//settings > banner
export const FileArea = styled.div`
  margin-top: 52px;
`;

export const InfoBox = styled.div`
  & > p {
    color: #555;
    font-size: 12px;

    &.right {
      text-align: right;
    }
    &.bold {
      font-weight: 500;
    }

    &.blue {
      color: #0086e5;
    }
    &.gray {
      color: #888;
      & > span {
        color: #0086e5;
        margin-right: 10px;
      }
    }
    &.black {
      color: #222;
    }
    &.red {
      color: #dd3333;
    }
  }

  &.padding {
    margin-top: 4px;
    padding: 4px 8px;
  }

  &.etc {
    background: #f8f8f8;
  }
`;

export const SearchNameList = styled.div`
  width: 320px;
  padding: 8px 0 24px;
  background: #ffffff;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  position: absolute;
  z-index: 1;
  top: 50px;
  left: 108px;
  max-height: 270px;
  overflow-y: auto;
  overflow-x: hidden;

  & > button {
    white-space: nowrap;
    width: 100%;
    padding: 13px 0 13px 16px;
    text-align: left;
    & span {
      color: #0086e5;
    }
    & p {
      font-weight: 400;
      font-size: 16px;
      color: #555555;
      & > span {
        color: #bbbbbb;
      }
    }
  }

  &.w_240 {
    width: 240px;
  }
  &.w_560 {
    width: 560px;
  }
`;

export const CellFlexBox = styled.div`
  display: flex;
  margin-top: 4px;
  white-space: nowrap;

  & b {
    word-break: break-all;
  }

  &:first-of-type {
    margin-top: 0;
  }

  & > p {
    font-size: 12px;
    color: #888;
    width: 34px;
    min-width: 34px;
    margin-right: 16px;

    &.long {
      width: 45px;
      min-width: 45px;
    }
  }

  & > span {
    color: #555;
    margin-right: 40px;

    &:last-of-type {
      margin-right: 0;
    }

    &.ellipsis {
      width: 88px !important;
      min-width: 88px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &.ellipsis_v2 {
      width: 395px !important;
      min-width: 395px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &.ellipsis2 {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    &.width100 {
      width: calc(100% - 138px);
    }
  }
  & .blue {
    color: #0086e5;
  }
  & .red {
    color: #dd3333;
  }

  ${({ width }) =>
    width &&
    css`
      & > p {
        width: ${width}px;
        min-width: ${width}px;
      }

      & > span {
        width: calc(100% - (${width}px + 16px));
      }
    `}
`;

export const ContentsLabel = styled(LabelText)`
  display: block;

  & .underline {
    text-decoration: underline;
    cursor: pointer;
  }

  & > p {
    font-size: 14px;
    font-weight: 500;
  }

  & > span {
    color: #0086e5;
    display: block;
    font-size: 12px;
    font-weight: 400;
  }
  &.none {
    display: none;
  }
`;

export const PartArea = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 869px;

  & > input {
    margin-top: 8px;
    width: 368px;
    height: 34px;
    background: #ffffff;
    border: 1px solid #bbbbbb;
    border-radius: 4px;
    padding: 0px 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.6;
    color: #222222;

    &::placeholder {
      color: #222222;
    }

    &:disabled {
      color: #bbb;
      border: 1px solid #eee;

      &::placeholder {
        color: #bbb;
      }
    }
  }

  & > button {
    width: 120px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #bbb;
    border: 1px solid #bbb;
    border-radius: 4px;
    margin-right: 4px;

    &:nth-of-type(n + 8) {
      margin-top: 8px;
    }
    &.selected {
      color: #0086e5;
      border-color: #0086e5;
    }
    &:last-of-type {
      margin-left: 4px;
      margin-right: 0px;

      &.selected {
        color: #dd3333;
        border-color: #dd3333;
      }
    }
  }
`;

export const PreviewDiv = styled.div`
  position: relative;
  border-radius: 4px;
  margin-right: 8px;
  min-width: 120px;
  width: 120px;
  height: 120px;

  & button {
    position: absolute;
    top: 5px;
    right: 5px;
    & svg {
      display: block;
    }
  }
`;

export const SearchArea = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
`;
