import React from "react";
import { useRecoilValue } from "recoil";
import AlertModal from "../../component/modal/AlertModal";
import FeeCalculatorModal from "../../component/modal/FeeCalculatorModal";
import ImageModal from "../../component/modal/ImageModal";
import {
  alertModalState,
  feeCalculatorModalState,
  imageModalState,
} from "../../recoil/atom/modal";

function Core() {
  const imageModal = useRecoilValue(imageModalState);
  const alertModal = useRecoilValue(alertModalState);
  const feeCalculatorModal = useRecoilValue(feeCalculatorModalState);
  return (
    <>
      {imageModal !== null && <ImageModal />}
      {alertModal !== null && <AlertModal />}
      {feeCalculatorModal !== null && <FeeCalculatorModal />}
    </>
  );
}

export default Core;
