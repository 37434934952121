import loadable from "@loadable/component";

export const Root = loadable(() => import("./Root"));
export const Login = loadable(() => import("./user/Login"));
export const Check = loadable(() => import("./user/Check"));

// 대시보드
export const Dashboard = loadable(() => import("./dashboard/Dashboard"));
export const NoticeListPage = loadable(() =>
  import("./dashboard/NoticeListPage")
);
export const NoticeDetailPage = loadable(() =>
  import("./dashboard/NoticeDetailPage")
);

//사내공지
export const InhouseNotice = loadable(() => import("./inhouse/InhouseNotice"));
export const InhouseNoticeDetail = loadable(() =>
  import("./inhouse/InhouseNoticeDetail")
);

//as
export const As = loadable(() => import("./as/As"));
export const AsRegister = loadable(() => import("./as/AsRegister"));

//부속품 관리
export const Part = loadable(() => import("./part/Part"));
export const PartRegister = loadable(() => import("./part/PartRegister"));

//상담관리
export const Consult = loadable(() => import("./consult/Consult"));
export const ConsultRegister = loadable(() =>
  import("./consult/ConsultRegister")
);
export const ConsultImsiList = loadable(() =>
  import("./consult/ConsultImsiList")
);

//TDS
export const Tds = loadable(() => import("./tds/Tds"));
export const TdsRegister = loadable(() => import("./tds/TdsRegister"));

//위탁관리
export const Consignment = loadable(() => import("./consignment/Consignment"));
export const ConsignmentEdit = loadable(() =>
  import("./consignment/ConsignmentEdit")
);
//위탁관리
export const Schedule = loadable(() => import("./schedule/Schedule"));

//매입관리
export const Purchase = loadable(() => import("./purchase/Purchase"));
export const PurchaseEdit = loadable(() => import("./purchase/PurchaseEdit"));

//지점회계
export const Account = loadable(() => import("./account/Account"));

//설정
export const Settings = loadable(() => import("./settings/Settings"));

////////////

//주문관리
export const Order = loadable(() => import("./order/Order"));

// 고객관리
export const User = loadable(() => import("./userSetting/User"));
export const UserRegister = loadable(() =>
  import("./userSetting/UserRegister")
);
