import React from "react";
import { Redirect } from "react-router-dom";
import useUser from "../hooks/useUser";

function WithAccessAuth({ Component, level, redirectUrl }) {
  const { isAuthorizationByLevel } = useUser();

  if (!isAuthorizationByLevel(level, "access")) {
    return <Redirect to={redirectUrl ? redirectUrl : "/"} />;
  }

  return <Component />;
}

export default WithAccessAuth;
