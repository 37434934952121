import useUser from "../hooks/useUser";
import LoadingPopup from "../component/popup/LoadingPopup";

function withUser(WrappedComponent) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { user } = useUser();

  if (!user) return LoadingPopup;

  return WrappedComponent;
}

export default withUser;
