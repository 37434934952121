export function getSelectListValue(selectList, value) {
  return selectList.find((select) => select.value === value);
}

export const getCorrectBranchName = (branch) => {
  if (branch === "all") return "전체";
  if (branch === "부천점") return "부천점";
  if (branch === "광교점") return "광교점";
  if (branch === "인천점") return "인천점";
  if (branch === "반포점") return "반포점";
  return branch;
};

export function getInputValueOfNumberType(value) {
  let resultValue = value;
  if (value !== "0" && value[0] === "0") {
    resultValue = value.slice(1);
  }

  if (value === "") return 0;
  return Number(resultValue.replace(/[^0-9]/g, ""));
}

export const calculateFee = (price, percent) => {
  if (Number(price) <= 4000000) {
    return {
      a: Number(price) * +(percent.a / 100),
      b: 0,
      y: 0,
    };
  }

  if (Number(price) <= 10000000) {
    return {
      a: 4000000 * (percent.a / 100),
      b: (Number(price) - 4000000) * (percent.b / 100),
      y: 0,
    };
  }

  return {
    a: 4000000 * (percent.a / 100),
    b: 6000000 * (percent.b / 100),
    y: (Number(price) - 10000000) * (percent.y / 100),
  };
};
