import { atom } from "recoil";

export const imageModalState = atom({
  key: "imageModalState",
  default: null,
});

export const alertModalState = atom({
  key: "alertModalState",
  default: null,
});

export const feeCalculatorModalState = atom({
  key: "feeCalculatorModalState",
  default: null,
});

export const logoutSettingState = atom({
  key: "logoutSettingState",
  default: null,
});
