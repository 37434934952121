import { useRecoilState } from "recoil";
import { userState } from "../recoil/atom";
import useAlert from "./useAlert";

function useUser() {
  const [user, setUser] = useRecoilState(userState);
  const { accessAuthAlert, editAuthAlert } = useAlert();

  const isDisabledWithoutAllbranch = () => {
    if (!user) return false;
    if (user.level === "5") return false;
    if (user.branch === "all") return false;
    return true;
  };

  const isAuthorizationByLevel = (level, mode) => {
    if (parseInt(user?.level) < level) {
      mode === "access" && accessAuthAlert();
      mode === "edit" && editAuthAlert();
      return false;
    }
    return true;
  };

  return { user, setUser, isDisabledWithoutAllbranch, isAuthorizationByLevel };
}

export default useUser;
