import styled from "@emotion/styled";
import React from "react";
import { useEffect } from "react";
import OutsideClickHandler from "react-outside-click-handler";

function CommonModal({ children, onClose }) {
  useEffect(() => {
    if (!document || !document.body) return;
    document.body.style.cssText = `overflow: hidden`;
    return () => {
      if (!document || !document.body) return;
      document.body.style.cssText = `overflow: "auto"`;
    };
  }, []);

  return (
    <Wrapper>
      <OutsideClickHandler onOutsideClick={onClose}>
        {children}
      </OutsideClickHandler>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background: rgba(187, 187, 187, 0.77);

  display: flex;
  justify-content: center;
  align-items: center;
`;

export default CommonModal;
