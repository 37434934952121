import styled from "@emotion/styled";
import React from "react";

//type에 따라 style이 바뀜.  ex)line blue
const Button = ({ type, children, ...props }) => {
  return (
    <ButtonWrap type={type || "button"} className={type} {...props}>
      {children}
    </ButtonWrap>
  );
};

const ButtonWrap = styled.button`
  padding: 0 24px;
  height: 40px;
  border-radius: 4px;
  background: #003580;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border: 1px solid #003580;

  &.line {
    background: none;
    color: #222;
    border-color: #bbb;

    &.blue {
      color: #003580;
      border-color: #003580;
    }
    &.red {
      color: #dd3333;
      border-color: #dd3333;
    }
    &.sky_blue {
      color: #0086e5;
    }
  }

  &.round {
    border-radius: 100px;
  }

  &.small_round {
    border-radius: 4px;
  }

  &.w_72 {
    width: 72px;
    padding: 0;
    font-size: 12px;
  }

  &.large {
    height: 46px;
    padding: 0 32px;
  }

  &.x_large {
    height: 52px;
    padding: 0 40px;
  }

  &.transparent {
    background: none;
    border-color: transparent;
    color: #555;
  }

  &.red_bg {
    background: #dd3333;
    border-color: #dd3333;
  }
  &.sky_blue_bg {
    background: #0086e5;
    border-color: #0086e5;
  }
  &.right8 {
    margin-right: 8px;
  }

  &.h_34 {
    height: 34px;
  }
  &.h_28 {
    height: 28px;
  }
`;
export default Button;
