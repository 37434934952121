import { api } from "./utils/api";

function swrConfig(alertPopup) {
  return {
    fetcher(url, params) {
      return api.get(url, { params }).then((res) => {
        if (!res?.data?.success && res?.data?.alert === "권한이 없습니다.") {
          alertPopup({
            state: "fail",
            title: "접근불가",
            text: "접근 권한에 따라 접근이 불가합니다.",
          });
        } else if (
          !res?.data?.success &&
          res?.data?.alert === "접근이 불가능한 IP입니다."
        ) {
          alertPopup({
            state: "fail",
            title: "접근불가",
            text: "접근이 불가능한 IP입니다.",
          });
        } else {
          return res.data?.data || res.data;
        }
      });
    },
  };
}

export default swrConfig;
