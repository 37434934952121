import styled from "@emotion/styled";
import React from "react";
import { useRecoilState } from "recoil";
import { ReactComponent as CloseWriteIcon } from "../../assets/images/close-big-write.svg";
import { imageModalState } from "../../recoil/atom/modal";
import CommonModal from "./CommonModal";

function ImageModal() {
  const [modal, setModal] = useRecoilState(imageModalState);

  const onClose = () => {
    setModal(null);
  };

  if (modal === null) return null;
  return (
    <CommonModal onClose={onClose}>
      <Content>
        <img src={modal.url} alt="preview" className="preview" />
        <CloseWriteIcon className="x-icon" onClick={onClose} />
      </Content>
    </CommonModal>
  );
}

const Content = styled.div`
  position: relative;
  width: 500px;
  height: 513.4px;
  background: #eee;

  .x-icon {
    position: absolute;
    right: -24px;
    top: -24px;

    cursor: pointer;
  }

  .preview {
    width: 100%;
    height: 100%;
  }
`;

export default ImageModal;
