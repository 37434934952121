import styled from "@emotion/styled";
import React from "react";
import { useState } from "react";
import { useMemo } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import useSWR from "swr";
import { feeCalculatorModalState } from "../../recoil/atom/modal";
import { calculateFee, getInputValueOfNumberType } from "../../utils";
import Button from "../Button";
import { DotNum } from "../CommonFunction";
import Input from "../Input";
import ModalHeader from "./ModalHeader";
import ModalWrapper from "./ModalWrapper";

function FeeCalculatorModal() {
  const modal = useRecoilValue(feeCalculatorModalState);
  const reset = useResetRecoilState(feeCalculatorModalState);

  const [price, setPrice] = useState("");
  const [consignmentPrice, setConsignmentPrice] = useState("");
  const { data } = useSWR("/malladmin/setting/fee");

  const percent = useMemo(() => {
    return {
      a: data?.a_percent || 0,
      b: data?.b_percent || 0,
      y: data?.y_percent || 0,
    };
  }, [data]);

  const calculator = useMemo(() => {
    if (Number(price) <= 4000000) {
      return {
        a: Number(price) * +(percent.a / 100),
        b: 0,
        y: 0,
      };
    }

    if (Number(price) <= 10000000) {
      return {
        a: 4000000 * (percent.a / 100),
        b: (Number(price) - 4000000) * (percent.b / 100),
        y: 0,
      };
    }

    return {
      a: 4000000 * (percent.a / 100),
      b: 6000000 * (percent.b / 100),
      y: (Number(price) - 10000000) * (percent.y / 100),
    };
  }, [price, percent]);

  const totalCharge = useMemo(() => {
    return calculator.a + calculator.b + calculator.y;
  }, [calculator]);

  const handleChangePrice = (e) => {
    const value = e.target.value.replaceAll(",", "");

    if (String(value).length > 12) return;

    const fee = calculateFee(value, percent);
    const totalFee = fee.a + fee.b + fee.y;

    setPrice(value);
    setConsignmentPrice(value - totalFee);
  };

  const handleChangeConsignmentPrice = (e) => {
    const value = e.target.value.replaceAll(",", "");
    if (String(value).length > 12) return;

    const chargeA = getCharge(+value, +percent.a);

    if (+value + chargeA < 4000000) {
      setConsignmentPrice(Math.floor(value));
      return setPrice(Math.floor(+value + chargeA));
    }

    const chargeB =
      getCharge(getConsignmentPrice(4000000, +percent.a), +percent.a) +
      getCharge(+value - getConsignmentPrice(4000000, +percent.a), +percent.b);

    if (+value + chargeB < 10000000) {
      setConsignmentPrice(Math.floor(value));
      return setPrice(Math.floor(+value + chargeB));
    }

    const chargeC =
      getCharge(getConsignmentPrice(4000000, +percent.a), +percent.a) +
      getCharge(getConsignmentPrice(6000000, +percent.b), +percent.b) +
      getCharge(
        +value -
          (getConsignmentPrice(4000000, +percent.a) +
            getConsignmentPrice(6000000, +percent.b)),
        +percent.y
      );

    setConsignmentPrice(Math.floor(+value));
    return setPrice(Math.floor(+value + chargeC));
  };

  function getCharge(price, percent) {
    return (price * percent) / 100 / (1 - percent / 100);
  }

  function getConsignmentPrice(price, percent) {
    return (price / 100) * (100 - percent);
  }

  const handleConfirm = () => {
    modal?.onConfirm(price, consignmentPrice);
    reset();
  };

  if (!modal) return null;

  return (
    <ModalWrapper onClose={reset}>
      <Wrapper>
        <ModalHeader title="위탁 수수료 계산기" onClose={reset} />

        <BoxWrapper>
          <Box>
            <div className="label">판매가</div>
            <div className="w-full">
              <Input
                placeholder="판매가 입력"
                className="w-full"
                value={DotNum(price)}
                onChange={handleChangePrice}
              />
            </div>
          </Box>

          <Box className="mt-16">
            <div className="label">위탁가</div>
            <div className="w-full">
              <Input
                placeholder="위탁가 입력"
                className="w-full"
                value={DotNum(consignmentPrice)}
                onChange={handleChangeConsignmentPrice}
              />
            </div>
          </Box>
        </BoxWrapper>
        <Divide className="mt-24" />

        <BoxWrapper>
          <Box>
            <div className="label">1구간 ({percent.a}%)</div>
            <div className="value">{DotNum(calculator.a)}</div>
          </Box>
          <Box>
            <div className="label">2구간 ({percent.b}%)</div>
            <div className="value">{DotNum(calculator.b)}</div>
          </Box>
          <Box>
            <div className="label">3구간 ({percent.y}%)</div>
            <div className="value">{DotNum(calculator.y)}</div>
          </Box>
          <Box>
            <div className="label">수수료 합계</div>
            <div className="value">{DotNum(totalCharge)}</div>
          </Box>
        </BoxWrapper>

        {modal?.onConfirm && (
          <FooterWrapper>
            <Button className="round line" onClick={reset}>
              닫기
            </Button>
            <Button className="round" onClick={handleConfirm}>
              입력
            </Button>
          </FooterWrapper>
        )}
      </Wrapper>
    </ModalWrapper>
  );
}

const FooterWrapper = styled.div`
  margin-top: 60px;
  display: flex;
  gap: 8px;
`;

const Wrapper = styled.div`
  width: 720px;
  padding: 40px;
  background: #fff;
  box-shadow: 0px 6px 18px 0px rgba(0, 0, 0, 0.2);
  border-radius: 12px;

  & .mt-24 {
    margin-top: 24px;
  }

  & .w-full {
    width: 100%;
  }
`;

const BoxWrapper = styled.div`
  margin-top: 24px;

  display: flex;
  flex-direction: column;

  gap: 16px;
`;

const Box = styled.div`
  display: flex;
  align-items: cetner;

  height: 40px;

  & > .label {
    width: 100px;
    display: flex;
    align-items: center;
    font-family: Noto Sans KR;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(34, 34, 34, 1);
  }

  & .value {
    display: flex;
    align-items: center;
    font-family: Noto Sans KR;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(34, 34, 34, 1);
  }
`;

const Divide = styled.div`
  height: 1px;
  background: rgba(238, 238, 238, 1);
`;

export default FeeCalculatorModal;
