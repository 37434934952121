import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { userState } from "../recoil/atom";
import { endpoint } from "../utils/api";

function useLoad() {
  const setUser = useSetRecoilState(userState);
  const token = localStorage.getItem("token");

  useEffect(() => {
    fetch(`${endpoint}/manager/setting/info`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.code || !response.data) throw new Error(response.message);
        localStorage.setItem("user_id", response.data?.user_info?.id);
        localStorage.setItem(
          "user_position",
          response.data?.user_info?.position
        );
        localStorage.setItem("user_name", response.data?.user_info?.name);
        localStorage.setItem("user_level", response.data?.user_info?.level);
        localStorage.setItem("branch", response.data?.user_info?.branch);
        setUser(response.data?.user_info || null);
      })
      .catch((e) => {
        localStorage.clear();
        if (window.location.pathname === "/login") return;
        window.location.href = "/login";
      });
  }, []);
}

export default useLoad;
