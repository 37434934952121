import React, { useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { LabelText } from "./CommonStyle";

//labelComment : label 밑에 comment
//isPw : true 패스워드
//inputType : text, number
//helfText : 에러메세지, input 설명
//isError : true 에러메세지라 color red
//InputInnerButton : input 안에 버튼
//InputButton : input 옆에 버튼
//styleClass : input 가로 사이즈 160,240,320,560 및 스타일

const Input = ({
  icon = true,
  label,
  labelUnderline,
  labelComment,
  styleClass = "",
  isPw = false,
  inputType,
  helpText,
  disabled,
  unit,
  timer,
  InputButton,
  InputInnerButton,
  isError,
  labelStyle,
  focus,
  ...props
}) => {
  return (
    <div>
      <InputArea>
        {label && (
          <LabelArea>
            <LabelText
              className={
                labelUnderline ? `${labelStyle} underline` : labelStyle
              }
            >
              {label}
            </LabelText>
            {labelComment && <span>{labelComment}</span>}
          </LabelArea>
        )}

        <InputBox
          className={styleClass}
          disabled={disabled}
          isError={isError}
          InputButton={InputButton ? true : false}
        >
          <StyledInput
            {...props}
            type={isPw ? "password" : "text"}
            inputMode={inputType === "number" ? "decimal" : "text"}
            pattern={inputType === "number" && "[0-9]*"}
            disabled={disabled}
            onFocus={focus}
          />
          {unit && <Unit>{unit}</Unit>}

          {timer !== `03:00` && <Timer>{timer}</Timer>}
          {InputInnerButton && InputInnerButton}
        </InputBox>
        {InputButton && InputButton}
      </InputArea>
      {helpText && (
        <HelpText className={label ? "with_label" : ""} isError={isError}>
          {helpText}
        </HelpText>
      )}
    </div>
  );
};
const Timer = styled.div`
  font-size: 14px;
  color: rgba(221, 51, 51, 1);
  display: flex;
  align-items: center;
`;

const Unit = styled.p`
  display: flex;
  align-items: center;
  font-size: 14px;
`;

const InputBox = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  padding: 7px 12px;
  align-items: center;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #bbb;
  position: relative;

  ${({ isError }) =>
    isError &&
    css`
      border-color: #dd3333;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      border-color: #ddd;
      background: #f2f2f2;
    `}

  ${({ InputButton }) =>
    InputButton &&
    css`
      width: calc(100% - 84px);
    `}

  &.w_40 {
    padding: 0 2px;
    width: 40px;
  }
  &.w_80 {
    width: 80px;
  }

  &.w_160 {
    width: 160px;
  }
  &.w_200 {
    width: 200px;
  }
  &.w_240 {
    width: 240px;
  }
  &.w_254 {
    width: 254px;
  }

  &.w_320 {
    width: 320px;
  }
  &.w_356 {
    width: 356px;
  }
  &.w_530 {
    width: 530px;
  }
  &.w_560 {
    width: 560px;
  }

  &.h_28 {
    height: 34px;
  }
  &.h_34 {
    height: 34px;
  }

  &.account {
    border-color: #0086e5;
    border-radius: 0;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  font-size: 14px;
  font-weight: normal;
  color: #1f1f1f;
  outline: 0;
  background: none;

  &::placeholder {
    color: #bbb;
  }
`;

const LabelArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > span {
    display: block;
    font-size: 12px;
    color: #0086e5;
  }
`;
const HelpText = styled.span`
  width: 100%;
  display: block;
  font-size: 12px;
  color: #888;
  margin-top: 4px;

  &.with_label {
    margin-left: 108px;
  }

  ${({ isError }) =>
    isError &&
    css`
      color: #dd3333;
    `}
`;

const InputArea = styled.div`
  width: 100%;
  display: flex;

  & > button {
    min-width: 80px;
    width: 80px;
    margin-left: 4px;
  }
`;

export default Input;
